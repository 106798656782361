import { EnumType } from "typescript";
import { MaintenanceStatusTypes } from "../models/maintenances";
import { IRentStatus } from "../models/clients";

export const MODUL_CLIENTS = "clients";

export const TRANSLATION_KEY = {
  closedAt: "closedAt",
  notes: "notes",
  archive: "archive",
  rentOrder: "rentOrder",
  newRentOrder: "newRentOrder",
  clientOrder: "clientOrder",
  withoutClient: "withoutClient",
  saveAndAdd: "saveAndAdd",
  selectChecklist: "selectChecklist",
  columns: "columns",
  saveChanges: "saveChanges",
  linkedItemsMessage: "linkedItemsMessage",
  datetime: "datetime",
  successfulChanged: "successfulChanged",
  editView: "editView",
  addView: "addView",
  saveViewChanges: "saveViewChanges",
  customFieldTypeDontExsits: "customFieldTypeDontExsits",
  warningDynColumnEditingText: "warningDynColumnEditingText",
  warningDynColumnEditingTitle: "warningDynColumnEditingTitle",
  continueWithActionDynColumnDeleting: "continueWithActionDynColumnDeleting",
  infoValuesOfDynColumnWillBeUnique: "infoValuesOfDynColumnWillBeUnique",
  column_unique: "column_unique",
  accountRoles: "accountRoles",
  selectRoles: "selectRoles",
  selectCategories: "selectCategories",
  typeSelectData: "typeSelectData", // "Select data type",
  customSelectData: "customSelectData",
  selectMaintenanceType: "selectMaintenanceType",
  selectFieldType: "selectFieldType",
  typeName: "typeName",
  withoutAsset: "withoutAsset",
  addClient: "addClient",
  serachByAssetOrOrderNumber: "serachByAssetOrOrderNumber",
  rentOrders: "rentOrders",
  searchByClientName: "searchByClientName",
  clients: "clients",
  rentAssetTitle: "rentAssetTitle",
  editClient: "editClient",
  client: "client",
  rentAsset: "rentAsset",
  cantRemovePermissionBecaouseItContainsPare: "cantRemovePermissionBecaouseItContainsPare",
  addAllAssetToUser: "addAllAssetToUser",
  addAllUsersToAsset: "addAllUsersToAsset",
  usersRequired: "usersRequired",
  userAssetRequired: "userAssetRequired",
  notHaveAnyAsset: "notHaveAnyAsset",
  basicInfo: "basicInfo",
  panelAssets: "panelAssets",
  forAssets: "forAssets",
  scanItem: "scanItem",
  itemNotExisitsInDataBase: "itemNotExisitsInDataBase",
  week: "week",
  month: "month",
  year: "year",
  day: "day",
  quarter: "quarter",
  date: "date",
  export: "export",
  connectedToParts: "connectedToParts",
  toContiniuePleaseLogin: "toContiniuePleaseLogin",
  maxFileSize: "maxFileSize",
  stableConnectionReq: "stableConnectionReq",
  addPanel: "addPanel",
  editPanel: "editPanel",
  quantityLimit: "quantityLimit",
  statuses: "statuses",
  currentQty: "currentQty",
  workRequests: "workRequests",
  reportWorkRequest: "reportWorkRequest",
  searchPanels: "searchPanels",
  requestPanels: "requestPanels",
  copiedToClipboard: "copiedToClipboard",
  items: "items",
  spendPartWithoutOrder: "spendPartWithoutOrder",
  addExpenseName: "addExpenseName",
  addLocationName: "addLocationName",
  mineAllFilterInfo: "mineAllFilterInfo",
  purchasedQuantityGraph: "purchasedQuantityGraph",
  purchasedQuantityAgainstTotalQuantity: "purchasedQuantityAgainstTotalQuantity",
  totalPurchasedQuantityValue: "totalPurchasedQuantityValue",
  totalPurchasedQuantity: "totalPurchasedQuantity",
  consumedQuantityGraph: "consumedQuantityGraph",
  consumedQuantityAgainstTotalQuantity: "consumedQuantityAgainstTotalQuantity",
  totalConsumedQuantityValue: "totalConsumedQuantityValue",
  totalConsumedQuantity: "totalConsumedQuantity",
  partPriceTrend: "partPriceTrend",
  unsuccessfulWO: "unsuccessfulWO",
  woSuccessGraph: "woSuccessGraph",
  reprotfourmaintenance: "reprotfourmaintenance",
  maintenanceNumberGraph: "maintenanceNumberGraph",
  maintenanceTimeGraph: "maintenanceTimeGraph",
  successCount: "successCount",
  failCount: "failCount",
  successPercentage: "successPercentage",
  MTBF: "MTBF",
  allOrders: "allOrders",
  myOrders: "myOrders",
  enterCorrectEmail: "enterCorrectEmail",
  mine: "mine",
  removeAssets: "removeAssets",
  assignAssets: "assignAssets",
  currencyWarningTitle: "currencyWarningTitle",
  currencyWarning: "currencyWarning",
  article_with_code_exists: "article_with_code_exists",
  cantDelUserBecauseHeIsMasterAdmin: "cantDelUserBecauseHeIsMasterAdmin",
  sum: "sum",
  workingCosts: "workingCosts",
  materialCosts: "materialCosts",
  filedRequired: "filedRequired",
  save: "save",
  password: "password",
  loginError: "loginError",
  name: "name",
  nameAndSurname: "nameAndSurname",
  role: "role",
  roles: "roles",
  assets: "assets",
  asset: "asset",
  category: "category",
  address: "address",
  phoneNumber: "phoneNumber",
  pricePerHour: "pricePerHour",
  note: "note",
  errorOnSaveData: "errorOnSaveData",
  successOnSaveData: "successOnSaveData",
  changePassword: "changePassword",
  oldPassword: "oldPassword",
  congirmPassError: "congirmPassError",
  pleaseConfirmPassword: "pleaseConfirmPassword",
  confirmPassword: "confirmPassword",
  passwordAreNotMAtch: "passwordAreNotMAtch",
  searchGouesByNameEmailRole: "searchGouesByNameEmailRole",
  errorOnGetData: "errorOnGetData",
  edit: "edit",
  delete: "delete",
  continueWithAction: "continueWithAction",
  yes: "yes",
  no: "no",
  editUserAccount: "editUserAccount",
  loadMore: "loadMore",
  parentAsset: "parentAsset",
  description: "description",
  autopilot: "autopilot",
  warehouse: "warehouse",
  buyPrice: "buyPrice",
  supplier: "supplier",
  assetCategory: "assetCategory",
  location: "location",
  add: "add",
  relevantLinks: "relevantLinks",
  errorOnDeleteData: "errorOnDeleteData",
  createdDate: "createdDate",
  assetType: "assetType",
  searchAssetByNameTypeCategoryLocation: "searchAssetByNameTypeCategoryLocation",
  assetRoute: "assetRoute",
  userAsset: "userAsset",
  accounts: "accounts",
  accountsOnAsset: "accountsOnAsset",
  upload: "upload",
  materialsOfAsset: "materialsOfAsset",
  cancel: "cancel",
  maintenanceCategory: "maintenanceCategory",
  maintenanceTypes: "maintenanceTypes",
  partsCategories: "partsCategories",
  erroFileLoad: "erroFileLoad",
  supplierCategories: "supplierCategories",
  categoryParent: "categoryParent",
  fieldType: "fieldType",
  addedCustomFields: "addedCustomFields",
  alreadyExsits: "alreadyExsits",
  return: "return",
  permissions: "permissions",
  all: "all",
  clearAll: "clearAll",
  addMoreData: "addMoreData",
  close: "close",
  nameOfCustomFieldCantHave: "nameOfCustomFieldCantHave",
  idpdv: "idpdv",
  web: "web",
  accept: "accept",
  deny: "deny",
  searchByName: "searchByName",
  sendREquest: "sendREquest",
  company: "company",
  noDataFoundSendInvitation: "noDataFoundSendInvitation",
  sendEmail: "sendEmail",
  userOnPartners: "userOnPartners",
  restUsers: "restUsers",
  iWork: "iWork",
  documents: "documents",
  searchSupplierBySomething: "searchSupplierBySomething",
  maintenance: "maintenance",
  partnerMaintenance: "partnerMaintenance",
  part: "part",
  partners: "partners",
  emailNotValid: "emailNotValid",
  logout: "logout",
  columnTitle: "columnTitle",
  table: "table",
  tree: "tree",
  uploadedFiles: "uploadedFiles",
  reportedBy: "reportedBy",
  mainExecutor: "mainExecutor",
  otherExecutors: "otherExecutors",
  partner: "partner",
  employee: "employee",
  plannedStart: "plannedStart",
  deadline: "deadline",
  duration: "duration",
  comands: "comands",
  orderNumber: "orderNumber",
  takeMaintenance: "takeMaintenance",
  pauseMaintenance: "pauseMaintenance",
  open: "open",
  status: "status",
  startMaintenance: "startMaintenance",
  finishMaintenance: "finishMaintenance",
  openMaintenance: "openMaintenance",
  runForEveryone: "runForEveryone",
  reason: "reason",
  maintenanceOnHoldTypes: "maintenanceOnHoldTypes",
  doUWantSetNewMainExecutor: "doUWantSetNewMainExecutor",
  selectNewMainExecutor: "selectNewMainExecutor",
  onlyOneManOnMaintenance: "onlyOneManOnMaintenance",
  timeNeeded: "timeNeeded",
  startTime: "startTime",
  selectAsset: "selectAsset",
  selectType: "selectType",
  selectLocation: "selectLocation",
  selectExecutor: "selectExecutor",
  reject: "reject",
  qrcode: "qrcode",
  printQrcode: "printQrcode",
  photography: "photography",
  informations: "informations",
  companyName: "companyName",
  pdvId: "pdvId",
  language: "language",
  forgotPassword: "forgotPassword",
  notAMember: "notAMember",
  signUp: "signUp",
  alreadyAMember: "alreadyAMember",
  signIn: "signIn",
  noPhotography: "noPhotography",
  plannedDuration: "plannedDuration",
  type: "type",
  properties: "properties",
  showDocuments: "showDocuments",
  string: "string",
  number: "number",
  flag: "flag",
  select: "select",
  expand: "expand",
  collapse: "collapse",
  editSupplier: "editSupplier",
  video: "video",
  document: "document",
  image: "image",
  checklists: "checklists",
  tasks: "tasks",
  ok: "ok",
  enterEmailToResetYourPassword: "enterEmailToResetYourPassword",
  checklist: "checklist",
  openCalendar: "openCalendar",
  shareMaterials: "shareMaterials",
  selectMaterials: "selectMaterials",
  selectAssets: "selectAssets",
  previous: "previous",
  next: "next",
  done: "done",
  materialsSelected: "materialsSelected",
  selectAll: "selectAll",
  assetsSelected: "assetsSelected",
  searchAssetsByName: "searchAssetsByName",
  seeMore: "seeMore",
  userReports: "userReports",
  pass: "pass",
  fail: "fail",
  addChecklist: "addChecklist",
  addTask: "addTask",
  addPart: "addPart",
  code: "code",
  criticalQty: "criticalQty",
  uom: "uom",
  spend: "spend",
  qty: "qty",
  qtyUom: "qtyUom",
  price: "price",
  priceUom: "priceUom",
  partStorage: "partStorage",
  files: "files",
  avgPrice: "avgPrice",
  createdBy: "createdBy",
  addPartToStorage: "addPartToStorage",
  spendPart: "spendPart",
  spendPartQtyError: "spendPartQtyError",
  action: "action",
  confirm: "confirm",
  remove: "remove",
  executors: "executors",
  requestsList: "requestsList",
  numberOfRequests: "numberOfRequests",
  reportOrder: "reportOrder",
  confirmOrders: "confirmOrders",
  successful: "successful",
  unsuccessful: "unsuccessful",
  addReports: "addReports",
  title: "title",
  webAddres: "webAddres",
  canReportOrder: "canReportOrder",
  addUser: "addUser",
  setPassword: "setPassword",
  setPasswordToContinue: "setPasswordToContinue",
  locationNotDefined: "locationNotDefined",
  userProfile: "userProfile",
  registrationSuccessCheckYourMail: "registrationSuccessCheckYourMail",
  checkYourMail: "checkYourMail",
  onlyNumberCanInput: "onlyNumberCanInput",
  addAsset: "addAsset",
  send: "send",
  materials: "materials",
  tasksChecked: "tasksChecked",
  selectCategory: "selectCategory",
  searchOrders: "searchOrders",
  selectWarehouse: "selectWarehouse",
  workingTime: "workingTime",
  assetMarks: "assetMarks",
  addAssetMark: "addAssetMark",
  confirmOrder: "confirmOrder",
  maintenances: "maintenances",
  selectStatus: "selectStatus",
  searchExpenses: "searchExpenses",
  addExpense: "addExpense",
  locations: "locations",
  expenses: "expenses",
  tag: "tag",
  value: "value",
  addNotification: "addNotification",
  notifications: "notifications",
  emailForSendOrderToSupplier: "emailForSendOrderToSupplier",
  orderHasNoInCompanyExecutors: "orderHasNoInCompanyExecutors",
  enterVerificationCode: "enterVerificationCode",
  verify: "verify",
  spendWithoutPart: "spendWithoutPart",
  newPassword: "newPassword",
  enterNewPassword: "enterNewPassword",
  yourPasswordHasBeenSuccessfullyChanged: "yourPasswordHasBeenSuccessfullyChanged",
  register: "register",
  enterYourInformations: "enterYourInformations",
  login: "login",
  recoveryEmailHasBeenSuccessfullySent: "recoveryEmailHasBeenSuccessfullySent",
  preview: "preview",
  inModal: "inModal",
  task: "task",
  globalHourPrice: "globalHourPrice",
  globalCompanyCurrency: "globalCompanyCurrency",
  userReportRequired: "userReportRequired",
  showMaintenanceBefore: "showMaintenanceBefore",
  addQuantity: "addQuantity",
  pairSpend: "pairSpend",
  partsInTransport: "partsInTransport",
  partsToConfirm: "partsToConfirm",
  dateFormat: "dateFormat",
  selectDateFormat: "selectDateFormat",
  searchParts: "searchParts",
  addSupplier: "addSupplier",
  emailAlreadyExists: "emailAlreadyExists",
  email: "email",
  waitingForConfirmation: "waitingForConfirmation",
  plannedEnd: "plannedEnd",
  confirmedBy: "confirmedBy",
  minutes: "minutes",
  hours: "hours",
  sendedInvitations: "sendedInvitations",
  warehouseLocation: "warehouseLocation",
  openOrderTokenError: "openOrderTokenError",
  badInput: "badInput",
  repeatEvery: "repeatEvery",
  selectRepeatUnit: "selectRepeatUnit",
  manage_asset: "manage_asset",
  manage_partner: "manage_partner",
  manage_wo: "manage_wo",
  manage_settings: "manage_settings",
  manage_supplier: "manage_supplier",
  manage_user: "manage_user",
  manage_warehouse: "manage_warehouse",
  view_asset: "view_asset",
  add_part_on_wo: "add_part_on_wo",
  work_wo: "work_wo",
  confirm_wo: "confirm_wo",
  dashboard: "dashboard",
  humanresources: "humanresources",
  Croatian: "Croatian",
  English: "English",
  created: "created",
  createdAt: "createdAt",
  Email: "Email",
  Equipment: "Equipment",
  Realestate: "Realestate",
  transport: "transport",
  spec: "spec",
  parts: "parts",
  maintenancelist: "maintenancelist",
  calendar: "calendar",
  selectUser: "selectUser",
  selectSupplier: "selectSupplier",
  closed: "closed",
  assigned: "assigned",
  held: "held",
  completed: "completed",
  running: "running",
  requested: "requested",
  cancelled: "cancelled",
  costs: "costs",
  users: "users",
  Admin: "Admin",
  Technician: "Technician",
  Manager: "Manager",
  templates: "templates",
  templateNumber: "templateNumber",
  Status: "Status",
  period: "period",
  nextRun: "nextRun",
  piece: "piece",
  liter: "liter",
  meter: "meter",
  kilogram: "kilogram",
  entryhistory: "entryhistory",
  spendhistory: "spendhistory",
  searchGoesByNameCodeCategory: "searchGoesByNameCodeCategory",
  searchGoesByNameCategory: "searchGoesByNameCategory",
  searchGoesByNameCodeAsset: "searchGoesByNameCodeAsset",
  modeling: "modeling",
  categories: "categories",
  selectableList: "selectableList",
  newDynamicField: "newDynamicField",
  editDynamicField: "editDynamicField",
  editLocationName: "editLocationName",
  editExpenseName: "editExpenseName",
  editExpense: "editExpense",
  newChecklist: "newChecklist",
  editChecklistName: "editChecklistName",
  newAssetCategory: "newAssetCategory",
  editAssetCategory: "editAssetCategory",
  newMaintenanceCategory: "newMaintenanceCategory",
  editMaintenanceCategory: "editMaintenanceCategory",
  newMaintenanceType: "newMaintenanceType",
  editMaintenanceType: "editMaintenanceType",
  newPauseReason: "newPauseReason",
  editPauseReason: "editPauseReason",
  newItemCategory: "newItemCategory",
  editItemCategory: "editItemCategory",
  newSupplierCategory: "newSupplierCategory",
  editSupplierCategory: "editSupplierCategory",
  newUserRole: "newUserRole",
  editUserRole: "editUserRole",
  userRolesPermissions: "userRolesPermissions",
  newMaintenanceReport: "newMaintenanceReport",
  editMaintenanceReport: "editMaintenanceReport",
  analytics: "analytics",
  closeMaintenance: "closeMaintenance",
  itemsSpent: "itemsSpent",
  cost: "cost",
  dynamicFields: "dynamicFields",
  addOrder: "addOrder",
  enterLoginInfo: "enterLoginInfo",
  errorRegistration: "errorRegistration",
  storage: "storage",
  scanCode: "scanCode",
  scanCodeApple: "scanCodeApple",
  editPart: "editPart",
  totalPrice: "totalPrice",
  onlyNumber: "onlyNumber",
  costsVsPurchaseAssetValue: "costsVsPurchaseAssetValue",
  byCostsOfAsset: "byCostsOfAsset",
  avgCostPerMaintenance: "avgCostPerMaintenance",
  workCostsOfMaintenances: "workCostsOfMaintenances",
  materialCostsOfMaintenances: "materialCostsOfMaintenances",
  totalCostsOfMaintenances: "totalCostsOfMaintenances",
  startYourTime: "startYourTime",
  workingCostsDetail: "workingCostsDetail",
  lowQtyItems: "lowQtyItems",
  maintenanceToClose: "maintenanceToClose",
  mainWithoutReports: "mainWithoutReports",
  addUserReport: "addUserReport",
  editUserReport: "editUserReport",
  addExecutors: "addExecutors",
  allNotifications: "allNotifications",
  totalQty: "totalQty",
  maintenanceByType: "maintenanceByType",
  loggedAt: "loggedAt",
  loggedTechnicians: "loggedTechnicians",
  maintenancesByCategory: "maintenancesByCategory",
  costsByAsset: "costsByAsset",
  assetInMaintenances: "assetInMaintenances",
  MTTR: "MTTR",
  downtime: "downtime",
  numberOfStops: "numberOfStops",
  other: "other",
  totalTimeOfMaintenances: "totalTimeOfMaintenances",
  totalCountOfMaintenances: "totalCountOfMaintenances",
  filters: "filters",
  addMaintenance: "addMaintenance",
  newNotification: "newNotification",
  noPerrmission: "noPerrmission",
  next_run: "next_run",
  wrongInput: "wrongInput",
  assetSuccesfulyAdded: "assetSuccesfulyAdded",
  assetSuccesfulyUpdateted: "assetSuccesfulyUpdateted",
  spendPartsTitle: "spendPartsTitle",
  cantReportOrder: "cantReportOrder",
  selectLanguage: "selectLanguage",
  user: "user",
  time: "time",
  working: "working",
  addSelectableValue: "addSelectableValue",
  addStringValue: "addStringValue",
  addNumberValue: "addNumberValue",
  humanResources: "humanResources",
  suppliers: "suppliers",
  enabled: "enabled",
  disabled: "disabled",
  copyLinkSuccess: "copyLinkSuccess",
  copyLink: "copyLink",
  or: "or",
  template: "template",
  trigger: "trigger",
  customFields: "customFields",
  scheduleMaintenance: "scheduleMaintenance",
  settings: "settings",
  addLocation: "addLocation",
  searchLocations: "searchLocations",
  unitPriceInfo: "unitPriceInfo",
  infoReportRequired: "infoReportRequired",
  Requester: "Requester",
  Visitor: "Visitor",
  "asset-analytics": "asset-analytics",
  reportone: "reportone",
  "item-purchase": "item-purchase",
  "item-consumption": "item-consumption",
  "item-analytics": "item-analytics",
  maintenancesanalytics: "maintenancesanalytics",
  "wo-time": "wo-time",
  "wo-costs": "wo-costs",
  "wo-success": "wo-success",
  start: "start",
  stop: "stop",
  total_costs: "total_costs",
  maintenanceId: "maintenanceId",
  incident: "incident",
  correction: "correction",
  preventive: "preventive",
  now: "now",
  newTemplate: "newTemplate",
  newTask: "newTask",
  maintenanceTimeCategory: "maintenanceTimeCategory",
  maintenanceTimeType: "maintenanceTimeType",
  totalCosts: "totalCosts",
  maintenanceCostCategory: "maintenanceCostCategory",
  maintenanceCostType: "maintenanceCostType",
  closeButton: "closeButton",
  active: "active",
  inactive: "inactive",
  sendInvitationSuccessful: "sendInvitationSuccessful",
  sendInvitationFailed: "sendInvitationFailed",
  addNew: "addNew",
  runningOrders: "runningOrders",
  Incident: "Incident",
  Correction: "Correction",
  Preventive: "Preventive",
  haltTimes: "haltTimes",
  total: "total",
  addExecutorSuccessful: "addExecutorSuccessful",
  addExecutorFailed: "addExecutorFailed",
  manage_analytics: "manage_analytics",
  count: "count",
  enterReport: "enterReport",
  chooseParentAsset: "chooseParentAsset",
  pageNotFound: "pageNotFound",
  editAsset: "editAsset",
  credentialsNotCorrect: "credentialsNotCorrect",
  userNonExistent: "userNonExistent",
  dataNotFound: "dataNotFound",
  totalPartCount: "totalPartCount",
  totalPartPrice: "totalPartPrice",
  percentage: "percentage",
  termsOfServiceModalTitle: "termsOfServiceModalTitle",
  termsOfService: "termsOfService",
  metrikonTerms: "metrikonTerms",
  iAgreeToThe: "iAgreeToThe",
  registrationTermsOfService: "registrationTermsOfService",
  rent: "rent",
  rentCode: "rentCode",
  ownership: "ownership",
  clientAssetRentDeleteMessage: "clientAssetRentDeleteMessage",
  rentedBy: "rentedBy",
  ownedBy: "ownedBy",
  selectClient: "selectClient",
  smsAuthorization: "smsAuthorization",
  autoSendSignedDoc: "autoSendSignedDoc",
  requestSmsAuthorization: "requestSmsAuthorization",
  enterEmailAddress: "enterEmailAddress",
  electronicWorkOrderSignature: "electronicWorkOrderSignature",
  signedSuccessfully: "signedSuccessfully",
  signatory: "signatory",
  signatureInitiator: "signatureInitiator",
  sendSignedDocument: "sendSignedDocument",
  success: "success",
  sendDocumentAfterSignature: "sendDocumentAfterSignature",
  firstName: "firstName",
  lastName: "lastName",
  sign: "sign",
  completeBeforeSigning: "completeBeforeSigning",
  infoAutopilot: "infoAutopilot",
  infoReportWorkOrder: "infoReportWorkOrder",
  serachByAssetOrRentalCode: "serachByAssetOrRentalCode",
};

export const MODULES = {
  clients: "clients",
};

export const IMAGE_EXTENSIONS = ["png", "jpeg", "jpg"];

export const TEMPLATE_STATUSES = ["active", "inactive"];

export const TEMPLATE_PERIODS = ["years", "months", "weeks", "days", "hours"]; // minutes uklonjeno dana 2023-09-26 jer samo zaroka bazu

export const DATE_FORMATS: string[] = [
  "DD.MM.YYYY - HH:mm",
  "MM.DD.YYYY - HH:mm",
  "HH:mm - DD.MM.YYYY",
  "DD-MM-YYYY HH:mm",
];

export const ASSET_TYPE = { wrh: "wrh" };

export const MATERIAL_EXTENSIONS = ".jepg,.jpg,.png,.mp4,.pdf";

export const IMAGE_TYPES = ["image/png", "image/jpeg"];

export const ICON_SIZE = 15;

export const CUSTOM_FIELD_HALF_KEY = "_customFieldHalfKey"; // dobijem formu za custom fieldsovima kao objekt sve te kljuceve moram strpat u array pomocou ovoga znam koji kljuc pripada za custom field

export const PREVENTIVE_TYPE_ID = 1;

export const REPEATING_UNIT = ["hour", "day", "week", "month", "year"];

export const CHECKLIST_TASK_TYPES = ["string", "number", "flag", "select"];

export const PART_UOM = ["piece", "liter", "meter", "kilogram"];

export const RENT_ORDER_STATUSES: Array<IRentStatus> = ["active", "inactive"];

interface ILanguage {
  label: string;
  alpha2Code: Languages;
}

export enum Languages {
  English = "en",
  Croatian = "hr",
  Bosnian = "bs",
  Serbian = "rs",
  German = "de",
}

export const LANGUAGES: ILanguage[] = [
  { label: "Hrvatski", alpha2Code: Languages.Croatian },
  { label: "English", alpha2Code: Languages.English },
  { label: "Bosanski", alpha2Code: Languages.Bosnian },
  { label: "Srpski", alpha2Code: Languages.Serbian },
  { label: "Deutsch", alpha2Code: Languages.German },
];

export const MOMENT_LANGUAGES: { [key in Languages]: string } = {
  bs: "rs",
  de: "de",
  en: "en",
  hr: "hr",
  rs: "rs",
};

export const I18N_LANGUAGES: { [key in Languages]: string } = {
  bs: "ba",
  de: "de",
  en: "en",
  hr: "hr",
  rs: "rs",
};

export const STATUSES: MaintenanceStatusTypes[] = [
  "requested",
  "open",
  "assigned",
  // "shared",
  "running",
  "held",
  "completed",
  "closed",
  "cancelled",
];
export const LAYOUT_GRIDS = {
  sm: 24,
  lg: 12,
  md: 24,
  xxl: 8,
  xl: 8,
};

export const INPUT_NUMBER_FORMATTER = {
  decimalSeparator: ",",
  formatter(value: any) {
    return `${value}`.replace(".", ",");
  },
};

/**
 * @property {number} maxFileSize - max file size in MB
 * @property {number} largeFileSize - max file size in MB for large files
 */
export const UPLOAD_FILES_LIMITS = {
  maxFileSize: 30,
  largeFileSize: 10,
};

export const DONUT_CHART_CONFIG = {
  appendPadding: 10,
  radius: 0.8,
  innerRadius: 0.6,
  label: {
    type: "outer",
    offset: "-50%",
    content: "{value}",
    autoRotate: true,
    // layout: [
    //   { type: 'interval-adjust-position' },
    //   { type: 'adjust-color' },
    // ],
    style: {
      textAlign: "center",
      fontSize: 18,
      fill: "#434343",
    },
  },
  interactions: [
    {
      type: "element-selected",
    },
    {
      type: "element-active",
    },
    {
      type: "pie-statistic-active",
    },
  ],
};

export const PHONE_NUMBER_PLACEHOLDERS: { [language in Languages]: string } = {
  bs: "+387",
  hr: "+385",
  rs: "+381",
  en: "+44",
  de: "+49",
};
