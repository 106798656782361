import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  ModalProps,
  Space,
  Checkbox,
  Tooltip,
  message,
  Alert,
} from "antd";
import React, { useEffect, useState } from "react";
import { CustomFieldV2, TypeCustomFieldV2 } from "../../models/settings";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { BasicObject } from "../../models";
import { SelectTagInput } from "./SelectTagInput";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  createCustomFieldV2XHR,
  updateCustomFieldXHR,
} from "../../store/reducers/settings/actionCreator";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { meXHR } from "../../store/reducers/user/actionCreators";
import { getSupplierCategoriesXHR } from "../../store/reducers/supplier/actionCreator";

export type FormType =
  | "workorder"
  | "orderconfirm"
  | "items"
  | "entry"
  | "spend"
  | "asset"
  | "account"
  | "suppliers"
  | "clients"
  | "rent_order";

export type FormModal = {
  type: FormType;
  customField: CustomFieldV2;
};

export type FormCondition = { title: string; data: BasicObject[] | undefined; placeholder: string };

interface IProps {
  data: CustomFieldV2 | undefined;
  condition?: FormCondition;
  modalProps: ModalProps;
  formType: FormType;
}

const CustomFieldFormV2: React.FC<IProps> = ({ data, condition, modalProps, formType }) => {
  const [form] = Form.useForm<CustomFieldV2>();
  const [selectingCondition, set_selectingCondition] = useState<TypeCustomFieldV2>();
  const { updateCustomFieldStatus } = useAppSelector((state) => state.settingsReducer);
  const [unique, set_unique] = useState<boolean>(false);
  const [loading, set_loading] = useState(false);
  const dispatch = useAppDispatch();

  const _onFinsih = (values: Omit<CustomFieldV2, "cf_id">) => {
    let _values = {
      cf_category: formType,
      data: {
        ...values,
        unique: unique,
        data: values.data ? values.data : null,
        condition: values.condition || [],
      },
    };

    if (!data?.cf_id) {
      // create
      set_loading(true);
      createCustomFieldV2XHR(
        {
          body: _values,
          successCallback: () => {
            set_loading(false);
            let e: any = null; // ne sluzi nicem vec da se typing ne buni
            meXHR(
              {
                loading: "refresh",
              },
              dispatch,
            );
            modalProps.onCancel && modalProps.onCancel(e);
            form.resetFields();
          },
          errorCallback: (error) => {
            set_loading(false);

            if (error?.response?.data?.message === "invalid_name") {
              message.error(t(error?.response?.data?.message));
              return;
            }
            if (error.response.data?.message?.custom_field) {
              message.error(
                t(error.response.data.message.message || "").replace(
                  "$_dynamic_column",
                  error.response.data.message.custom_field,
                ),
              );
              return;
            }
            message.error(t(TRANSLATION_KEY.errorOnSaveData));
          },
        },
        dispatch,
      );
      return;
    }

    updateCustomFieldXHR(
      {
        body: {
          data: {
            ...values,
            unique: unique,
            data: values.data ? values.data : null,
            cf_id: data.cf_id,
            condition: values.condition || [],
          },
          cf_category: formType,
        },
        successCallback: () => {
          let e: any = null; // ne sluzi nicem vec da se typing ne buni
          meXHR(
            {
              loading: "refresh", // moraju se refreshat table_structure unutar view-a
            },
            dispatch,
          );
          modalProps.onCancel && modalProps.onCancel(e);
          form.resetFields();
        },
        errorCallback: (error) => {
          if (error?.response?.data?.message === "invalid_name") {
            message.error(t(error?.response?.data?.message));
            return;
          }
          if (error.response.data?.message?.custom_field) {
            message.error(
              t(error.response.data.message.message || "").replace(
                "$_dynamic_column",
                error.response.data.message.custom_field,
              ),
            );
            return;
          }
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
        },
      },
      dispatch,
    );

    //update
  };

  useEffect(() => {
    form.setFieldsValue({ ...data, condition: data?.condition || [] });
    set_selectingCondition(data?.type);
    set_unique(data?.unique || false);
    getSupplierCategoriesXHR({}, dispatch);
  }, [data]);

  return (
    <Modal
      destroyOnClose
      footer={null}
      visible={modalProps.visible}
      onCancel={(e) => {
        modalProps.onCancel && modalProps.onCancel(e);
        form.resetFields();
      }}
      title={
        data?.cf_id
          ? t(TRANSLATION_KEY.editDynamicField) + " - " + modalProps.title
          : t(TRANSLATION_KEY.newDynamicField) + " - " + modalProps.title
      }
    >
      {data?.cf_id && (
        <Alert
          style={{ marginBottom: 12 }}
          message={t(TRANSLATION_KEY.warningDynColumnEditingTitle)}
          description={t(TRANSLATION_KEY.warningDynColumnEditingText)}
          type="warning"
        />
      )}
      <Form
        layout="vertical"
        form={form}
        onFinish={_onFinsih}
        onChange={(e) => {
          console.log(e.target);
        }}
      >
        <Form.Item
          rules={[
            {
              required: true,
              message: t(TRANSLATION_KEY.filedRequired),
            },
          ]}
          label={t(TRANSLATION_KEY.fieldType)}
          name={"type"}
        >
          <Select
            disabled={data?.cf_id !== undefined}
            onChange={(e) => {
              set_selectingCondition(e);
              if (["datetime", "flag", "select"].includes(e)) {
                form.setFieldsValue({ unique: false });
                set_unique(false);
              }
            }}
            placeholder={t(TRANSLATION_KEY.selectFieldType)}
          >
            <Select.Option value="text">{t(TRANSLATION_KEY.string)}</Select.Option>
            <Select.Option value="number">{t(TRANSLATION_KEY.number)}</Select.Option>
            <Select.Option value="datetime">{t(TRANSLATION_KEY.datetime)}</Select.Option>
            <Select.Option value="select">{t(TRANSLATION_KEY.select)}</Select.Option>
            <Select.Option value="flag">{t(TRANSLATION_KEY.flag)}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name={"unique"}
          valuePropName="checked"
          style={{
            display:
              selectingCondition === "datetime" ||
              selectingCondition === "flag" ||
              selectingCondition === "select"
                ? "none"
                : "flex",
          }}
        >
          <Checkbox
            checked={unique}
            onChange={() => set_unique(!unique)}
            disabled={data?.cf_id !== undefined}
          >
            {t(TRANSLATION_KEY.column_unique)}
          </Checkbox>
          <Tooltip title={t(TRANSLATION_KEY.infoValuesOfDynColumnWillBeUnique)}>
            <InfoCircleOutlined />
          </Tooltip>
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: t(TRANSLATION_KEY.filedRequired),
            },
          ]}
          label={t(TRANSLATION_KEY.name)}
          name={"name"}
        >
          <Input placeholder={t(TRANSLATION_KEY.typeName)} />
        </Form.Item>

        {condition && (
          <Form.Item
            rules={[
              {
                required: ["asset", "account"].includes(formType),
                message: t(TRANSLATION_KEY.filedRequired),
              },
            ]}
            label={condition.title}
            name={"condition"}
          >
            <Select
              filterOption={(input, option) => {
                let a: any = option?.children;
                return a?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              showSearch
              allowClear
              mode="multiple"
              placeholder={condition.placeholder}
            >
              {condition.data?.map((x) => (
                <Select.Option key={x.id} value={x.id}>
                  {t(x.name)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => {
            return prevValues.type !== currentValues.type;
          }}
        >
          {({ getFieldValue }) => (
            <Form.Item
              rules={[
                {
                  required: selectingCondition === "select",
                  message: t(TRANSLATION_KEY.filedRequired),
                },
              ]}
              label={t(TRANSLATION_KEY.customSelectData)}
              name="data"
              style={{ display: getFieldValue("type") === "select" ? "block" : "none" }}
            >
              <SelectTagInput />
            </Form.Item>
          )}
        </Form.Item>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Form.Item>
            <Button
              onClick={(e) => {
                modalProps.onCancel && modalProps.onCancel(e);
                form.resetFields();
              }}
            >
              {t(TRANSLATION_KEY.cancel)}
            </Button>
            <Button
              loading={loading || updateCustomFieldStatus === "loading"}
              style={{ marginLeft: 12 }}
              type="primary"
              htmlType="submit"
            >
              {t(TRANSLATION_KEY.save)}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};
export default CustomFieldFormV2;

export const getFormSetup = (
  form: FormType,
  customField: undefined | CustomFieldV2,
  data: BasicObject[] | undefined,
) => {
  let condition: undefined | FormCondition = undefined;
  let title = "";
  if (form === "workorder") {
    condition = {
      title: t(TRANSLATION_KEY.maintenanceTypes),
      data: data || undefined,
      placeholder: t(TRANSLATION_KEY.selectMaintenanceType),
    };
    title = t(TRANSLATION_KEY.maintenance);
  } else if (form === "orderconfirm") {
    title = t(TRANSLATION_KEY.confirmOrder);
  } else if (form === "items") {
    condition = {
      title: t(TRANSLATION_KEY.categories),
      data: data || undefined,
      placeholder: t(TRANSLATION_KEY.selectCategory),
    };
    title = t(TRANSLATION_KEY.part);
  } else if (form === "entry") {
    title = t(TRANSLATION_KEY.entryhistory);
  } else if (form === "spend") {
    title = t(TRANSLATION_KEY.spendhistory);
  } else if (form === "asset") {
    condition = {
      title: t(TRANSLATION_KEY.assetCategory),
      data: data || undefined,
      placeholder: t(TRANSLATION_KEY.selectCategory),
    };
    title = t(TRANSLATION_KEY.asset);
  } else if (form === "account") {
    condition = {
      title: t(TRANSLATION_KEY.selectRoles),
      data: data || undefined,
      placeholder: t(TRANSLATION_KEY.selectCategory),
    };
    title = t(TRANSLATION_KEY.humanResources);
  } else if (form === "suppliers") {
    condition = {
      title: t(TRANSLATION_KEY.supplierCategories),
      data: data || undefined,
      placeholder: t(TRANSLATION_KEY.selectCategory),
    };
    title = t(TRANSLATION_KEY.supplier);
  } else if (form === "clients") {
    title = t(TRANSLATION_KEY.client);
  } else if (form === "rent_order") {
    title = t(TRANSLATION_KEY.rentOrders);
  }

  return {
    condition,
    title: title,
  };
};
